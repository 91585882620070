<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'invoices' }"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert>

    <b-row
      v-if="invoiceData"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h1 class="text-primary">
                    {{
                      invoiceData.licenses.length
                        ? invoiceData.licenses[0].business_name
                        : invoiceData.psv_licenses[0].sacco_name
                    }}
                  </h1>
                </div>
                <h5 class="text-primary">
                  {{
                    invoiceData.licenses.length
                      ? invoiceData.licenses[0].business_type
                      : invoiceData.psv_licenses[0].car_license_plate_number
                  }}
                </h5>
                <!-- <div v-if="invoiceData.licenses.length" class="d-flex">
                  <p
                    class="card-text mb-25 mr-1"
                    v-for="jurisdiction in invoiceData.licenses[0].jurisdiction"
                    :key="jurisdiction"
                  >
                    {{ jurisdiction }},
                  </p>
                </div>
                <div v-else class="d-flex">
                  <p
                    class="card-text mb-25 mr-1"
                    v-for="jurisdiction in invoiceData.psv_licenses[0].jurisdiction"
                    :key="jurisdiction"
                  >
                    {{ jurisdiction }},
                  </p>
                </div> -->
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span
                    class="invoice-number"
                  >#{{ invoiceData.invoice_id }}</span>
                </h4>
                <div class="invoice-date-wrapper justify-content-end">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{
                      moment(invoiceData.created_at).format(
                        "dddd, MMMM Do YYYY"
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <div v-if="invoiceData.licenses.length">
            <!-- Invoice Description: Table -->
            <b-table
              v-if="invoiceData.licenses[0].metadata === null"
              :dark="true"
              responsive
              :items="
                invoiceData.licenses.length
                  ? invoiceData.licenses
                  : invoiceData.psv_licenses
              "
              :fields="['Item', 'total']"
            >
              <template #cell(Item)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.tariff.name }}
                </b-card-text>
              </template>
              <template #cell(total)="data">
                <b-card-text class="text-nowrap">
                  {{ new Intl.NumberFormat().format(data.item.tariff.price) }}
                </b-card-text>
              </template>
            </b-table>

            <b-table
              v-else
              :dark="true"
              responsive
              :items="getLicenseRatedItems(invoiceData.licenses)"
              :fields="['Item', 'total']"
            >
              <template #cell(Item)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.rate_index + " " + data.item.rated_item_index }}
                </b-card-text>
              </template>
              <template #cell(total)="data">
                <b-card-text class="text-nowrap">
                  {{
                    "Ksh." +
                      new Intl.NumberFormat().format(data.item.amount_index)
                  }}
                </b-card-text>
              </template>
            </b-table>
            <p
              v-if="invoiceData.licenses[0].metadata != null"
              class="px-1"
            >
              *The total cost is subject to a minimum fee of Ksh.{{
                new Intl.NumberFormat().format(
                  invoiceData.licenses[0].tariff.price
                )
              }}
            </p>
          </div>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Total -->
              <b-col
                cols="12"
                md="12"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item d-flex justify-content-end">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount font-weight-bold">
                      Ksh.
                      {{
                        new Intl.NumberFormat().format(
                          getInvoiceTotalAmount(invoiceData)
                        )
                      }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item d-flex justify-content-end">
                    <p class="invoice-total-title mr-1">
                      Discount:
                    </p>
                    <p class="invoice-total-amount font-weight-bold">
                      {{ invoiceData.discount }}%
                    </p>
                  </div>
                  <div v-if="invoiceData.payments.length">
                    <hr class="my-50">
                    <div class="invoice-total-item d-flex justify-content-end">
                      <p class="invoice-total-title mr-1">
                        Paid:
                      </p>
                      <p class="invoice-total-amount font-weight-bold">
                        Ksh.{{
                          new Intl.NumberFormat().format(
                            getAmountPaid(invoiceData)
                          )
                        }}
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item d-flex justify-content-end">
                      <p class="invoice-total-title mr-1">
                        Balance:
                      </p>
                      <p class="invoice-total-amount font-weight-bold">
                        Ksh.{{
                          new Intl.NumberFormat().format(
                            getInvoiceBalance(
                              invoiceData,
                              getDiscountedAmount(invoiceData)
                            )
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item d-flex justify-content-end">
                    <p class="invoice-total-title mr-1">
                      Total:
                    </p>
                    <p class="invoice-total-amount font-weight-bold">
                      Ksh.
                      {{
                        new Intl.NumberFormat().format(
                          getDiscountedAmount(invoiceData)
                        )
                      }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Download -->
          <b-button
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Download
          </b-button>

          <!-- Button: Make Payment -->
          <b-button
            v-if="invoiceData.status === 'Pending'"
            variant="warning"
            class="mb-75"
            block
            :to="{
              name: 'invoice-select-pay-method',
              params: { id: invoiceData.invoice_id },
            }"
          >
            Pay
          </b-button>
          <b-button
            v-else
            disabled
            variant="success"
          >
            PAID
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BTable,
} from 'bootstrap-vue'
import useInvoiceList from './useInvoiceList'
import invoiceStoreModule from './invoiceStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BTable,
  },
  setup() {
    const invoiceData = ref(null)
    const paymentDetails = ref({})
    const licenseType = ref('businesses')

    // Use toast
    const toast = useToast()

    const INVOICE_APP_STORE_MODULE_NAME = 'invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('invoice/fetchInvoice', {
        id: router.currentRoute.params.id,
      })
      .then(response => {
        if (response.data.data.psv_licenses) {
          licenseType.value === 'psvs'
        } else {
          licenseType.value = 'businesses'
        }
        invoiceData.value = response.data.data

        paymentPhoneNumber.value = JSON.parse(
          localStorage.getItem('user'),
        ).phone_number
        paymentInvoiceId.value = invoiceData.value.invoice_id
      })
      .catch(error => {
        invoiceData.value = undefined
      })

    const getLicenseRatedItems = licenses => {
      const rated_items = []
      licenses.forEach(license => {
        const result = Object.keys(JSON.parse(license.metadata)).map(key => [
          key,
          JSON.parse(license.metadata)[key],
        ])
        let amount = 0
        let rate = 0
        let rated_item = ''
        let index = 0
        for (const data in result[0][1]) {
          rated_item = license.tariff.tariff_category.rates.find(
            rate => rate.rated_item === data,
          ).description
          rate = license.tariff.tariff_category.rates.find(
            rate => rate.rated_item === data,
          ).cumulative_rate
          if (rate <= 1) {
            rate *= 100
            rate = `${String(rate)}%`
          } else {
            rate = `Ksh.${String(rate)}`
          }
          amount += Number(result[0][1][data])
          index = {
            rated_item_index: rated_item,
            rate_index: rate,
            amount_index: amount,
          }
          rated_items.push(index)
          index++
        }
      })
      return rated_items.slice(-1)
    }

    const getInvoiceTotalAmount = invoice => {
      let totalAmount = 0
      if (invoice.licenses.length) {
        invoice.licenses.forEach(license => {
          if (license.metadata !== null) {
            let rate = 0
            const result = Object.keys(JSON.parse(license.metadata)).map(
              key => [key, JSON.parse(license.metadata)[key]],
            )
            for (const data in result[0][1]) {
              totalAmount += Number(result[0][1][data])

              if (Number(result[0][1][data] !== 0)) {
                rate = license.tariff.tariff_category.rates.find(
                  rate => rate.rated_item === data,
                ).cumulative_rate
              }
            }
            totalAmount = rate * totalAmount
            if (totalAmount < license.tariff.price) {
              totalAmount = license.tariff.price
            }
          } else {
            totalAmount += license.tariff.price
          }
        })
      } else {
        invoice.psv_licenses.forEach(license => {
          totalAmount += license.tariff.price
        })
      }

      return totalAmount
    }

    const printInvoice = () => {
      store
        .dispatch('invoice/downloadInvoice', invoiceData.value.invoice_id)
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute(
            'download',
            `${invoiceData.value.invoice_id}.pdf`,
          )
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error accessing file. Please try again.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      isLoading,
      showPaymentModal,
      refEnterPhoneNumberForm,
      paymentInvoiceId,
      paymentPhoneNumber,
      processPayment,
      checkPaymentModal,
      checkPaymentInvoiceId,
      checkPayment,

      getAmountPaid,
      getInvoiceBalance,
      getDiscountedAmount,
    } = useInvoiceList()

    return {
      isLoading,
      invoiceData,
      paymentDetails,
      printInvoice,

      getInvoiceTotalAmount,
      getDiscountedAmount,
      getAmountPaid,
      getInvoiceBalance,

      showPaymentModal,
      refEnterPhoneNumberForm,
      paymentInvoiceId,
      paymentPhoneNumber,
      processPayment,

      checkPaymentModal,
      checkPaymentInvoiceId,
      checkPayment,

      getLicenseRatedItems,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
